// Social proof logic

let userQuotePos = 0;

const userQuoteLoop = setInterval(function () {
  nextUserQuote();
}, 30000);

document.querySelectorAll('.js_user_quote_close').forEach((element) => {
  element.addEventListener('click', (e) => {
    document
      .querySelector(`.js_user_quote_${e.target.dataset.id}`)
      .classList.remove('show');
    clearInterval(userQuoteLoop);
  });
});

function nextUserQuote() {
  if (window.screen.width > 768) {
    let cnt = 0;
    let newPos = userQuotePos + 1;
    document.querySelectorAll('.js_user_quote').forEach(() => {
      cnt += 1;
    });
    if (newPos > cnt) {
      newPos = 1;
    }
    cnt = 0;
    document.querySelectorAll('.js_user_quote').forEach((element) => {
      cnt++;
      if (cnt == newPos) {
        userQuotePos = newPos;
        element.classList.add('show');
        setTimeout(function () {
          element.classList.remove('show');
        }, 5500);
      }
    });
  }
}
