import { processEmailCapture } from '@/core/thinkhuge';

function init() {
  const nodes = document.querySelectorAll('.js-grab-email-button');

  if (!nodes.length) return;

  const grabEmailOnClick = (event) => {
    const target = event.currentTarget;
    const grabInput = target.previousElementSibling;
    const regPopupEmailInput = document.querySelector('.js-registration-email');

    if (
      regPopupEmailInput &&
      grabInput.classList.contains('js-grab-email-input')
    ) {
      const email = grabInput.value,
        source = grabInput.dataset.source || 'cta';

      processEmailCapture(email, source);

      regPopupEmailInput.value = email;
    }
  };

  nodes.forEach((element) => {
    element.addEventListener('click', grabEmailOnClick);
  });
}

init();
