import { Countdown } from '@/core/Countdown';

function init() {
  const selector = '.js-webinar-countdown';
  const nodes = document.querySelectorAll(selector);

  if (!nodes.length) return;

  const onCountdownFinish = (countdownNodes) => {
    countdownNodes.forEach((node) => {
      node.parentElement.style.display = 'none';
    });
  };

  const nextWebinarTime = parseInt(httApp.nextWebinarTime) * 1000;

  new Countdown(selector, nextWebinarTime, {
    useDays: true,
    onFinish: onCountdownFinish,
  });
}

init();
