import Cookies from 'js-cookie';

const exitPopup = document.querySelector('.js-exit-popup');

function isPopupDisabled() {
  // TODO - switch this back before going live - enabled for Murat benefit
  // return httApp.exitPopupDisabled; // enable for testing purposes, disable next line
  return httApp.exitPopupDisabled || Cookies.get('exit-popup');
}

function init() {
  if (!exitPopup || isPopupDisabled()) {
    return;
  }

  document.body.addEventListener('mouseleave', (event) => {
    if (isPopupDisabled()) {
      return;
    }
    // also trigger when mouse goes out of the page
    if (event.clientY <= 0 && httApp.mouse.clientY < 100) {
      exitModalOn();
    }
  });

  // trigger after 30 seconds dwell on a page
  // Disabled 21st Dec 2023
  // setTimeout(() => {
  //   exitModalOn();
  // }, 30000);

  function exitModalOn() {
    if (httApp.isAnyPopupActive) {
      return;
    }

    httApp.isAnyPopupActive = true;

    setTimeout(() => document.body.classList.add('modal-open'), 50);
    setTimeout(() => exitPopup.classList.add('on'), 50);

    httApp.exitPopupDisabled = true;
    Cookies.set('exit-popup', '1', { expires: 365 });
  }

  function exitModalOff() {
    httApp.isAnyPopupActive = false;
    // Pause any playing vimeo video if it exists
    if (document.querySelector('.js-success-video-object') !== null) {
      document
        .querySelector('.js-success-video-object')
        .contentWindow.postMessage('{"method":"pause"}', '*');
    }
    setTimeout(() => document.body.classList.remove('modal-open'), 50);
    setTimeout(() => exitPopup.classList.remove('on'), 300);
  }

  document.querySelectorAll('.js-exit-popup-close').forEach((element) => {
    element.addEventListener('click', () => {
      exitModalOff();
    });
  });

  document.addEventListener('mousemove', (event) => {
    httApp.mouse.clientX = event.clientX;
    httApp.mouse.clientY = event.clientY;
  });
}

init();
