import { redirectTR } from '@/core/traderoom';

export function modalOn(popup) {
  if (httApp.isAnyPopupActive) {
    return;
  }

  httApp.isAnyPopupActive = true;

  const section = document.getElementById('join');

  document.body.classList.add('modal-open');
  section.classList.add('on');
  setTimeout(() => popup.classList.add('animate-in'), 50);
}

export function modalOff(popup, registerCheckComplete) {
  const section = document.getElementById('join');

  section.classList.remove('on');
  document.body.classList.remove('modal-open');
  httApp.isAnyPopupActive = false;

  setTimeout(() => {
    popup.classList.remove('animate-in');
  }, 50);

  // stopVideo();

  // reload page if registered
  if (registerCheckComplete) {
    window.location.reload();
  }
}

export function redirectPage(url, tr) {
  if (tr) {
    redirectTR(tr.session, tr.ip, tr.fs_page, false, tr.tr_url);
  } else if (url) {
    window.location.href = url;
  } else {
    window.location.reload();
  }
}
