export function sendGAevent(
  eventCategory,
  eventAction,
  eventLabel,
  subscriptionValue = ''
) {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'DLVEvent',
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      subscriptionValue: subscriptionValue,
    });
  }
}
