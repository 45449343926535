export function elementIsHidden(el) {
  return el.offsetParent === null;
}

export function chainedAnimation(el, animationClass) {
  return new Promise((resolve) => {
    function handleAnimationEnd() {
      // console.log('animation end');
      resolve(el);
    }
    el.addEventListener('animationend', handleAnimationEnd, { once: true });
    el.classList.add(animationClass);
  });
}

export function chainedTransition(el, styleProps) {
  return new Promise((resolve) => {
    function handleTransitionEnd() {
      // console.log('transition end');
      resolve(el);
    }
    el.addEventListener('transitionend', handleTransitionEnd, { once: true });
    Object.entries(styleProps).forEach(([prop, value]) => {
      el.style.setProperty(prop, value);
    });
  });
}

export function switchActiveChild(child, activeClass, resetIfActive = false) {
  if (child.classList.contains(activeClass)) {
    if (resetIfActive) {
      child.classList.remove(activeClass);
    }
    return false;
  }

  Array.from(child.parentNode.children).forEach((el) =>
    el.classList.remove(activeClass)
  );

  child.classList.add(activeClass);

  return true;
}

export function clearSelect(select) {
  select.querySelectorAll('option').forEach((option) => option.remove());
}

export function addSelectOption(select, data) {
  return select.add(new Option(...data));
}

export function lazyLoadVideo(video, callback) {
  const sources = video.children;
  Array.from(sources).forEach((source) => {
    if (source.getAttribute('data-src'))
      source.setAttribute('src', source.getAttribute('data-src'));
  });

  video.addEventListener('loadeddata', () => {
    video.classList.remove('hidden');
    if (typeof callback === 'function') callback();
  });

  video.load();
}
