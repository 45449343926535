import { loadScript } from '@/core/utils';

let FbAuth, AppleAuth;

export async function loadGoogleAuth(handleCredentialResponse, init) {
  loadScript('https://accounts.google.com/gsi/client', () => {
    window.google.accounts.id.initialize({
      // eslint-disable-next-line camelcase
      client_id: httApp.googleClientId,
      callback: handleCredentialResponse,
    });

    init();
  });
}

export async function loadFacebookAuth() {
  if (!FbAuth) {
    FbAuth = new Promise((resolve) => {
      if (window.FB) {
        FbAuth = true;
        resolve();
      }

      loadScript('https://connect.facebook.net/en_US/all.js', function () {
        window.FB.init({
          cookie: true,
          status: true,
          appId: httApp.facebookAppId,
          version: 'v14.0',
        });

        FbAuth = true;
        resolve();
      });
    });
  }

  return FbAuth;
}

export async function loadAppleAuth() {
  if (!AppleAuth) {
    AppleAuth = new Promise((resolve) => {
      if (window.AppleID) {
        AppleAuth = true;
        resolve();
      }

      loadScript(
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        function () {
          AppleID.auth.init({
            clientId: 'com.web.howtotrade',
            scope: 'email name',
            redirectURI: httApp.appleRedirectUrl,
            state: httApp.appleIdState,
            usePopup: true,
          });

          AppleAuth = true;
          resolve();
        }
      );
    });
  }

  return AppleAuth;
}
