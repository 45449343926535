import { gsap as gsapRoot } from 'gsap';
import { ScrollTrigger as ScrollTriggerRoot } from 'gsap/ScrollTrigger';

gsapRoot.registerPlugin(ScrollTriggerRoot);
gsapRoot.defaults({});

ScrollTriggerRoot.defaults({});

export const gsap = gsapRoot;
export const ScrollTrigger = ScrollTriggerRoot;

export function animateOnce(tween) {
  return function ({ progress }) {
    gsap.killTweensOf(tween);
    if (tween.progress() < progress) {
      gsap.to(tween, {
        progress: progress,
        duration: 1,
        overwrite: true,
      });
    }
  };
}

// fix animations for lazy loaded images

function gsapImgLazyLoadHandler() {
  ScrollTrigger.refresh();
}

document
  .querySelectorAll('img[loading=lazy]')
  .forEach((img) => img.addEventListener('load', gsapImgLazyLoadHandler));
