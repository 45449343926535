export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function loadSvg(url, container = null) {
  const req = new XMLHttpRequest();

  req.onload = function (e) {
    let svg = document.implementation.createHTMLDocument('');

    svg.body.innerHTML = e.target.response;

    if (!container) {
      container = document.createElement('div');

      container.style.position = 'absolute';
      container.style.width = 0;
      container.style.height = 0;
      container.style.overflow = 'hidden';
      container.style.zIndex = -9999;

      document.body.appendChild(container);
    }

    container.appendChild(svg.body.firstElementChild);

    svg = null;
  };

  req.open('GET', url, true);
  req.responseType = 'text';
  req.send();
}

export function detectTouchScreen() {
  let hasTouchScreen = false;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  return hasTouchScreen;
}

export function detectMobileOs() {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return 'ios';
  }

  return false;
}

export function vimeoPlaceholderClickHandler(event) {
  const target = event.currentTarget;

  target.removeEventListener('click', vimeoPlaceholderClickHandler);
  delete target.dataset.placeholder;

  const url = `https://player.vimeo.com/video/${target.dataset.vimeoSrc}`;
  const muted = detectTouchScreen() ? '&muted=1' : '';

  target.innerHTML = `<iframe class="js-success-video-object" src="${url}?autoplay=1${muted}" allow="autoplay; fullscreen" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
}

export function loadScript(url, onload) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;

  if (onload) {
    script.onload = onload;
  }

  script.src = url;
  document.head.appendChild(script);
}

export function loadStylesheet(url, onload) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';

  if (onload) {
    link.onload = onload;
  }

  link.href = url;
  document.head.appendChild(link);
}

export function randomString(len = 8) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  let res = '';
  for (let i = 0; i < len; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    res += chars.substring(rnum, rnum + 1);
  }
  return res;
}

export function randomInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function pushFileToBrowser(url, addDownload = true) {
  const tmpLink = document.createElement('a');
  const link = decodeURI(url);

  tmpLink.style.display = 'none';

  tmpLink.setAttribute('href', link);
  tmpLink.setAttribute('target', '_blank');

  if (addDownload) {
    tmpLink.setAttribute('download', link.split('/').pop());
  }

  document.body.appendChild(tmpLink);
  tmpLink.click();

  setTimeout(() => {
    document.body.removeChild(tmpLink);
  }, 1000);
}

export function debounce(func, wait, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
