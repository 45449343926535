// import Cookies from 'js-cookie';

function init() {
  const offsetTime = new Date().getTimezoneOffset() * 60;
  httApp.userTimezoneOffsetSeconds = offsetTime;
  // Cookies.set('userTimezoneOffsetSeconds', offsetTime, { expires: 1 });
}

export function getTimezoneOffsetSeconds() {
  // return parseInt(Cookies.get('userTimezoneOffsetSeconds'));
  return httApp.userTimezoneOffsetSeconds;
}

export function isToday(someDate) {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

export function getHumanDate(someDate) {
  const day = someDate.getDate();
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
    someDate
  );

  return `${month}\u00A0${day}`;
}

export function getHumanTime(someDate) {
  return someDate.toLocaleTimeString('default', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

init();
