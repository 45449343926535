import { emitter } from '@/helpers/emitter';

const openFreshchatHandler = () => {
  if (window.fcWidget) {
    window.fcWidget.open();
    window.fcWidget.show();
  }
};

const chatButtons = document.querySelectorAll('.js-open-freshchat');

chatButtons.forEach((button) => {
  button.addEventListener('click', openFreshchatHandler);
});

// Global after load handler to unlock buttons

httApp.freshchatInitHandler = () => {
  emitter.emit('freshchat:ready');
  chatButtons.forEach((button) => {
    button.disabled = false;
    button.classList.remove('loading');
  });
};
