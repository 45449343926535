import { gsap } from '@/helpers/gsap';

const containers = document.querySelectorAll('.js-reveal-container');

containers.forEach((container) => {
  gsap.fromTo(
    container.querySelectorAll('.js-reveal-fade-up'),
    { opacity: 0, y: 50 },
    {
      opacity: 1,
      y: 0,
      stagger: 0.33,
      scrollTrigger: {
        trigger: container,
        once: true,
        start: 'top 90%',
      },
    }
  );
});
