import { modalOn } from '@/helpers/form-dom';
import { processEmailCapture } from '@/core/thinkhuge';

function init() {
  const nodes = document.querySelectorAll('.js-hero-email-btn');
  const popup = document.querySelector('.js-popup');

  // tabs nodes
  const registerTab = popup.querySelector('.js-register');
  const loginTab = popup.querySelector('.js-login');
  const landingSuccessTab = popup.querySelector('.js-landing-success');
  const forgotTab = popup.querySelector('.js-forgot');

  const allTabs = [registerTab, loginTab, landingSuccessTab, forgotTab];

  if (!nodes.length) return;

  const grabEmailOnClick = (event) => {
    const target = event.currentTarget;
    const action = target.dataset.action;
    const grabInput = target.previousElementSibling;
    const regPopupEmailInput = document.querySelector('.js-registration-email');
    popup.classList.add('hide-steps');

    if (regPopupEmailInput && grabInput.classList.contains('js-hero-email')) {
      const email = grabInput.value,
        source = grabInput.dataset.source || 'cta';

      processEmailCapture(email, source);

      regPopupEmailInput.value = email;

      // Had to add this because of the exit popup
      if (document.querySelector('.js-exit-popup')) {
        if (document.querySelector('.js-exit-popup').classList.contains('on')) {
          httApp.isAnyPopupActive = false;
          setTimeout(() => document.body.classList.remove('modal-open'), 50);
          setTimeout(
            () =>
              document.querySelector('.js-exit-popup').classList.remove('on'),
            300
          );
        }
      }

      modalOn(popup);
      for (let i = 0; i < allTabs.length; i++) {
        if (allTabs[i] === registerTab) continue;

        allTabs[i].classList.toggle('active', false);
      }

      if (registerTab) registerTab.classList.toggle('active', true);

      const headings = {
        default: 'Start learning how to trade today!',
        academy: 'Want more videos?',
        liveStreams: 'Join our next live stream today',
      };
      const subheadings = {
        freeTrial:
          'Create your free account and unlock access now. No credit card required.',
        webinar: 'Create your account and book your webinar slot',
        monitor: 'Create your account to enter our prize draw',
        courses: 'Create your account to save your course progress',
        tradingPlan: 'Create your account to download our free trading plan',
        academy: 'Join now',
        liveStreams: 'Join now',
        chartPatternSeries: 'Create your account and unlock course videos',
      };

      const registerForm = popup.querySelector('.js-register-form');
      const registerHeading = popup.querySelector('.js-popup-register-heading');
      const registerSubheading = popup.querySelector(
        '.js-popup-register-subheading'
      );
      const redirect = registerForm.querySelector(
        "input[name='register_redirect']"
      ).value;

      if (action) {
        switch (action) {
          case 'academy': {
            popup.classList.add('hide-right');
            popup.classList.add('hide-steps');

            registerHeading.innerText = headings.academy;
            registerSubheading.innerText = subheadings.academy;

            registerTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.removeAttribute('data-action');
                element.removeAttribute('data-redirect');
              });

            loginTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.removeAttribute('data-action');
                element.removeAttribute('data-redirect');
              });
            break;
          }
          case 'live-streams': {
            popup.classList.add('hide-right');
            popup.classList.add('hide-steps');

            registerHeading.innerText = headings.liveStreams;
            registerSubheading.innerText = subheadings.liveStreams;

            registerTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.removeAttribute('data-action');
                element.removeAttribute('data-redirect');
              });

            loginTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.removeAttribute('data-action');
                element.removeAttribute('data-redirect');
              });
            break;
          }
          case 'affiliate-program': {
            popup.classList.add('hide-right');
            popup.classList.add('hide-steps');
            registerForm.setAttribute('data-action', 'affiliate-program');
            break;
          }
          case 'courses':
          case 'monitor':
          case 'tradingPlan': {
            popup.classList.add('hide-right');
            popup.classList.add('hide-steps');
            popup.classList.add(
              ['monitor', 'tradingPlan'].includes(action) ? 'hide-login' : null
            );

            registerSubheading.innerText = subheadings[action];

            registerForm.setAttribute('data-action', action);
            registerForm.setAttribute('data-redirect', redirect);

            registerTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.setAttribute('data-action', action);
                element.setAttribute('data-redirect', redirect);
              });

            loginTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.setAttribute('data-action', action);
                element.setAttribute('data-redirect', redirect);
              });

            break;
          }
          case 'chartPatternSeries': {
            popup.classList.add('hide-right');
            popup.classList.add('hide-steps');

            registerSubheading.innerText = subheadings[action];

            registerForm.setAttribute('data-action', action);
            registerForm.setAttribute('data-redirect', false);

            registerTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.setAttribute('data-action', action);
                element.setAttribute('data-redirect', false);
              });

            loginTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.setAttribute('data-action', action);
                element.setAttribute('data-redirect', false);
              });

            break;
          }
          default:
            popup.classList.add('hide-right');
            popup.classList.add('hide-steps');

            registerHeading.innerText = headings.default;
            registerSubheading.innerText = subheadings.freeTrial;

            registerTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.removeAttribute('data-action');
                element.removeAttribute('data-redirect');
              });

            loginTab
              .querySelectorAll('.js-register-link')
              .forEach((element) => {
                element.removeAttribute('data-action');
                element.removeAttribute('data-redirect');
              });
            break;
        }
      }
    }
  };

  nodes.forEach((element) => {
    element.addEventListener('click', grabEmailOnClick);
  });
}

init();
