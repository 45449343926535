import { smFunc } from '@/core/thinkhuge';

export function fetchAndRedirectTR(forceOverride = false) {
  return smFunc
    .getJson('/app/auth/process/?do=getSessionTR', 'POST', {})
    .then((data) => {
      if (data.result === 'success') {
        redirectTR(
          data.session,
          data.ip,
          forceOverride ? forceOverride : data['fs_page'],
          data['traderoom_redir_after_login'],
          data['tr_url']
        );
      } else if (data.result === 'fail') {
        throw data.reason;
      }
    });
}

export function redirectTR(session, ip, fsPage, redirAfterLogin, trUrl) {
  const par = {
    s: session,
    i: ip,
    site: 'htt',
  };
  if (fsPage) {
    par.initPage = fsPage;
  }
  if (redirAfterLogin) {
    par.redirPage = redirAfterLogin;
  }
  if (httApp.trData.selectedPlan) {
    par.httPlan = httApp.trData.selectedPlan;
  }
  if (httApp.trData.promocode) {
    par.httPromocode = httApp.trData.promocode;
  }

  post(trUrl, par);
}

function post(path, params) {
  const f = document.createElement('form');
  f.method = 'post';
  f.action = path;
  f.style.display = 'none';

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key) && params[key]) {
      const h = document.createElement('input');
      h.type = 'hidden';
      h.name = key;
      h.value = params[key];
      f.appendChild(h);
    }
  }

  document.body.appendChild(f);
  f.submit();
}
