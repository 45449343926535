import { smFunc } from '@/core/thinkhuge';

// eslint-disable-next-line camelcase
window.sw_core = new (function () {
  this.allowDebug = false;
  this.systemURL = '';
  this.siteid = 0;
  this.update = false;
  this.publickey =
    'BPFaF_lXD49bNeKhjmgXsFMKZFUrIX4cJNtQ3447BM4eE9Mm-ChZrs3Yl5opw9cc7bXqRCqDVpGforyXq2s88FI';
  const publicKeyEnc = false;

  this.debug = function (output, warn) {
    if (this.allowDebug) {
      // eslint-disable-next-line no-console
      console.log(output);
    } else {
      if (warn) {
        console.warn(output);
      }
    }
  };

  this.confirm = function () {
    if (!this.systemURL) {
      console.error('System URL must be specified.');
      return false;
    }
    if (this.siteid == 0) {
      console.error('System Site must be specified.');
      return false;
    }

    if (this.publickey.length == 0) {
      console.error("Your public key hasn't been specified");
      return false;
    }
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      this.debug("Notifications aren't supported.", true);
      return false;
    }

    return true;
  };

  this.init = function () {
    if (!this.confirm) {
      return;
    }
    if (!publicKeyEnc) {
      this.publickey = this.urlBase64ToUint8Array(this.publickey);
    }

    // Check the current Notification permission.
    // If its denied, it's a permanent block until the
    // user changes the permission
    if (Notification.permission === 'denied') {
      this.debug('The user has blocked notifications.', true);
      return;
    }
    // Check if push messaging is supported
    if (!('PushManager' in window)) {
      this.debug("Push messaging isn't supported.", true);
      return;
    }

    this.debug('Initializing Service Worker', false);
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service.js').then((reg) => {
        if (this.update) {
          reg.update();
        }
        this.initialiseState();
      });
    } else {
      this.debug('Service workers not supported in this browser', true);
    }
  };

  this.initialiseState = function () {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then((subscription) => {
          if (!subscription) {
            this.subscribe();
            return;
          }
          this.storeSubscription(subscription);
        })
        .catch((err) => {
          // eslint-disable-next-line prefer-template
          this.debug('Error during getSubscription()' + err, true);
        });
    });
  };

  this.subscribe = function () {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.publickey,
        })
        .then((subscription) => {
          this.storeSubscription(subscription);
        })
        .catch((e) => {
          if (Notification.permission === 'denied') {
            // The user denied the notification permission which
            // means we failed to subscribe and the user will need
            // to manually change the notification permission to
            // subscribe to push messages
            this.debug('Permission for Notifications was denied', true);
          } else {
            // A problem occurred with the subscription; common reasons
            // include network errors, and lacking gcm_sender_id and/or
            // gcm_user_visible_only in the manifest.
            // eslint-disable-next-line prefer-template
            this.debug('Unable to subscribe to push.' + e, true);
          }
        });
    });
  };

  this.storeSubscription = function (subscription) {
    if (typeof subscription === 'object') {
      // this.debug(subscription);
      smFunc
        .getJson(this.systemURL, 'POST', {
          obj: JSON.stringify(subscription),
          endpoint: subscription.endpoint,
          siteid: this.siteid,
        })
        .then((response) => {
          this.debug(response.reason, true);
        });
    }
  };

  this.urlBase64ToUint8Array = function (base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  };
})();
