import FormReg from '@/core/FormReg';
import { loadGoogleAuth } from '@/helpers/socialLogin';
import { sendGAevent } from '@/core/analytics';

export default class FormManager {
  constructor() {
    this.forms = [];
    this.currentForm = null;
    this.appleCallbackSuccess = null;
    this.appleCallbackFailure = null;

    document.querySelectorAll('.js-register-form').forEach((form) => {
      const formReg = new FormReg(form);
      form.formRegInstance = formReg;
      formReg.initEventListeners();
      this.forms.push(formReg);
    });

    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
      this.appleCallbackSuccess &&
        this.appleCallbackSuccess(JSON.stringify(event.detail));
    });

    document.addEventListener('AppleIDSignInOnFailure', (error) => {
      console.error('AppleIDSignInOnFailure', error);
      this.appleCallbackFailure && this.appleCallbackFailure(error);
    });

    loadGoogleAuth(this.handleGoogleRegisterResponse.bind(this), () => {
      document.querySelectorAll('.js-register-form').forEach((form) => {
        const btn = form.querySelector('.googleSignInButton');
        window.google.accounts.id.renderButton(btn, {
          locale: 'en-GB',
          // eslint-disable-next-line camelcase
          click_listener: () => {
            this.setCurrentForm(form);
            this.googleSocialRegister();
          },
        });
      });
    });
  }

  setCurrentForm(formElement) {
    this.currentForm = this.forms.indexOf(formElement?.formRegInstance);
  }

  handleGoogleRegisterResponse(response) {
    this.forms[this.currentForm]?.toggleSpinner();
    this.forms[this.currentForm]?.socialSuccessCallback(
      response.credential,
      'google'
    );
  }

  googleSocialRegister() {
    sendGAevent('ext-cta', 'social-google', window.location.pathname);
  }
}
