import { fetchAndRedirectTR } from '@/core/traderoom';

const trButtons = document.querySelectorAll('.js-traderoom-link');

export function disableButtons(state) {
  trButtons.forEach((button) => {
    button.disabled = state;
  });
}

export function assignListenersToTrLinks() {
  const trButtons = document.querySelectorAll('.js-traderoom-link');
  if (trButtons) {
    trButtons.forEach((element) =>
      element.addEventListener('click', () => {
        disableButtons(true);

        if (window.self === window.top) {
          fetchAndRedirectTR()
            .then(() => {
              disableButtons(false);
            })
            .catch(() => {
              disableButtons(false);
            });
        } else {
          window.open(`${window.location.origin}/?traccess`);
        }
      })
    );
  }
}

assignListenersToTrLinks();
