import { getRecaptchaToken, smFunc } from '@/core/thinkhuge';
import { sendGAevent } from '@/core/analytics';
import { validateForm, showSystemError } from '@/helpers/form-validation';
import { addEvent } from '@/helpers/eventLog';
import { Countdown } from '@/core/Countdown';

const forms = document.querySelectorAll('.js-cta-download-form');

forms.forEach((form) => {
  form.addEventListener('submit', async (e) => {
    e.preventDefault();

    const form = e.target,
      recaptchaToken = await getRecaptchaToken(httApp.recaptchaSiteKey),
      formData = Object.fromEntries(new FormData(form)),
      errDiv = form.querySelector('#downloadErrMsg');

    formData.recaptchaToken = recaptchaToken;

    const valid = validateForm(form);

    if (valid) {
      try {
        const response = await smFunc.getJson(
          '/app/downloads/process/',
          'POST',
          formData
        );

        if (response.result == 'success') {
          window.open(response.download);
        } else {
          console.error(response);
          showSystemError(errDiv, response.reason);
        }
      } catch (error) {
        console.error('Send data failed', error);
        showSystemError(
          errDiv,
          'Unable to process your email, please try again'
        );
      }
    }
  });
});

document.querySelectorAll('.js-reg-event').forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const { eventSource } = el.dataset;
    sendGAevent('cta-click', eventSource, window.location.pathname);
    addEvent('cta-click', eventSource, window.location.pathname);
  });
});

document.querySelectorAll('.js-trustpilot').forEach((el) => {
  el.addEventListener('click', () => {
    sendGAevent('ext-cta', 'trustpilot', window.location.pathname);
  });
});

const countdownBlogStream = document.querySelector('.js-countdown-blog-stream');
const countdownBlogStreamTitle = document.querySelector(
  '.js-countdown-blog-stream-title'
);

const countdownBlogPromo = document.querySelector('.js-countdown-blog-promo');
const countdownBlogPromoTitle = document.querySelector(
  '.js-countdown-blog-promo-title'
);

const onCountdownStreamFinish = () => {
  countdownBlogStream.style.display = 'none';
  countdownBlogStreamTitle.style.display = 'none';
};

const onCountdownPromoFinish = () => {
  countdownBlogPromo.style.display = 'none';
  countdownBlogPromoTitle.style.display = 'none';
};

window.addEventListener('load', function () {
  const endOfTodayTime = new Date().setHours(24, 0, 0, 0);

  new Countdown('.js-countdown-blog-promo', endOfTodayTime, {
    useDays: false,
    cookie: 'monthly_promo_ribbon_timer_new',
    onFinish: onCountdownPromoFinish,
    hideSeconds: false,
  });

  smFunc.getJson('/app/streams/process/', 'POST', {}).then((data) => {
    if (data.result === 'success' && data[0] !== undefined) {
      new Countdown('.js-countdown-blog-stream', data[0].dateTimestamp * 1000, {
        useDays: false,
        onFinish: onCountdownStreamFinish,
        hideSeconds: false,
      });
    }
  });
});
